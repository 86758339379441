/* CensorshipPage.module.css */
.censorshipPage {
  width: 100vw;
  width: 100dvw;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

/* Mobile */
@media (max-aspect-ratio: 1) {
  .polls > h1 {
    padding-block-start: 1.5em;
  }
}

.imageBar {
  display: flex;
  flex-flow: column nowrap;
  /* width: 1fr; */
  overflow: hidden;
  /* height: 60px; */
  width: 100%;
  position: relative;
  /* transition: all 2s ease-out; */
}

.articleContainer {
  display: flex;
  flex-flow: column nowrap;
  /* height: 100vh; */
  width: 100%;
  justify-content: center;
  /* width: 20px; */
  align-items: center;
  /* position: absolute; */
  max-height: 1px;
  /* top: 0; */
  overflow: hidden;
  /* transition: all 2s ease-out; */
}

.show .articleContainer {
  max-height: 300px;
  justify-content: flex-start;
  flex-shrink: 0;
}

.headerImage {
  width: 100%;
  height: 120px;
  /* Additional styles */
  flex-shrink: 0;
}

.imageImg {
  width: 100%;
  /* max-height: 120px; */
}

.imgCont {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* max-height: 100px; */
}

.show .imgCont {
  height: 80px;
}

.show.imageBar {
  flex-shrink: 0;
}

@keyframes floating {
  0% {
    transform: translate(
      var(--translate-x-start, 0px),
      var(--translate-y-start, 0px)
    );
  }
  50% {
    transform: translate(
      var(--translate-x-end, 0px),
      var(--translate-y-end, 0px)
    );
  }
  100% {
    transform: translate(
      var(--translate-x-start, 0px),
      var(--translate-y-start, 0px)
    );
  }
}

.borders.censorshipPage .songs {
  background-image: url(../images/other/song_bg_border3.png);
  background-color: var(--color-theme-background);
  color: black;
  max-height: none;
  background-size: 30%;
  background-repeat: repeat;
}

.censorshipPage .songs {
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--color-theme-foreground);
  color: var(--color-theme-background);
  font-family: var(--ff-narkis-yair);
  padding: 0 2em;

  margin: 2em 0;
  margin-inline-start: -2em;
  position: relative;
  overflow: auto;
  max-height: 40vh;
  width: 100%;

  background-image: url(../images/other/song_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  transition: background-color 0.1s ease-out;
  transition-property: background-color, color;
}

.censorshipPage .songs .borderContainer {
  display: flex;
  flex-flow: column nowrap;
  gap: 2em;
  padding: 2em 1em;
  padding-top: 0;
}

.censorshipPage .songs h1 {
  font-family: var(--ff-hadassa);
  font-weight: 900;
  font-style: normal;
  color: var(--color-black);
  margin: 0;
}

.censorshipPage .songs section {
  display: flex;
  flex-flow: column nowrap;
  gap: 1em;
  line-height: 1;
}
.censorshipPage .songs section .song .separator {
  margin-block-end: 0.5em;
}

.censorshipPage .songs section .song p {
  line-height: 1;
}

.borders.censorshipPage .songs section .song p {
  line-height: 1.3;
  font-weight: 700;
}
.censorshipPage .songs section .song p + p {
  margin-block-start: 0.2em;
}

.censorshipPage .songs section .song p + .separator {
  margin-block-start: 2em;
}

.censorshipPage .terms {
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--color-dark-green);
  color: var(--color-black);
  font-family: var(--ff-hadassa);
  padding: 0 2em;

  margin: 2em 0;
  margin-inline-start: -2em;
  position: relative;
  overflow: auto;
  max-height: 40vh;
  width: 100%;
}
.censorshipPage .terms .borderContainer {
  display: flex;
  flex-flow: column nowrap;
  gap: 2em;
  border: none;
  border-right: 2px dotted black;
  padding: 2em 1em;
  padding-top: 0;
  margin-top: 2em;
}

.censorshipPage .terms h1 {
  font-family: var(--ff-hadassa);
  font-weight: 900;
  font-size: 1.5rem;
  font-style: normal;
  color: var(--color-black);
  margin: 0;
}

.censorshipPage .interview .terms h1::before {
  content: "";
  display: none;
}

.censorshipPage .terms section {
  display: flex;
  flex-flow: column nowrap;
  gap: 1em;
  line-height: 1;
}
.censorshipPage .terms section .term h2 {
  font-size: 1.5rem;
  font-weight: 900;
  display: inline;
  line-height: 1;
  margin-inline-end: 0.5em;
}

.censorshipPage .terms section .term p {
  display: inline;
  font-family: "Narkissim";
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1;
}

.censorshipPage .terms section .term p > u {
  text-decoration: 2px dotted underline;
  text-underline-offset: 0.2em;
}

.censorshipPage article {
  max-width: 100%;
  padding: 2em;
  color: var(--color-theme-foreground);
  display: flex;
  flex-flow: column nowrap;
  gap: 2em;
  padding-top: 4em;
  cursor: default;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: var(--color-theme-background);
  transition: background-color 0.1s ease-out;
  transition-property: background-color, color;
  margin-block-end: 8em;
}

.censorshipPage article.poll {
  padding: 0;
  margin-block-end: 0;
}

.censorshipPage article.poll > main {
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
}
.censorshipPage article.poll > main > .polls {
  height: 100%;
}

.censorshipPage article main .debates {
  position: relative;
  display: flex;
  /* margin-inline: -0.2em; */
}

.censorshipPage article main .debates .debate {
  position: absolute;
  background-color: var(--color-white);
  color: var(--color-black);
  border-radius: 8px;
  display: flex;
  flex-flow: column nowrap;
  width: 85%;
  background-color: var(--color-white);
  transition: all 0.15s ease-out;
  top: 0;
  right: 0;
  filter: drop-shadow(0 0 3px black);
  padding: 0.2em 1em;
  gap: 1em;
}

.censorshipPage article main .debates .debate.active {
  z-index: 1;
}

.censorshipPage article main .debates .debate.active header ::after {
  content: "<";
}

.censorshipPage article main .debates .debate.active:active {
  transform: translate(-4px, -4px);
}

.censorshipPage article main .debates .debate:not(.active) {
  top: 0;
  right: 150px;
}
.censorshipPage article main .debates .debate:not(.active):hover {
  transform: translate(-4px, -4px);
}

.censorshipPage article main .debates .debate:not(.active):active {
  transform: translate(-8px, -8px);
}

.censorshipPage article main .debates .debate:nth-child(odd) {
  background-color: rgb(203, 203, 203);
}

.censorshipPage article main .debates .debate header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: end;
  gap: 0.5em;
  align-items: center;
  border: none;
  border-bottom: 2px dotted var(--color-black);
  padding-block: 0.3em 0.4em;
}

.censorshipPage article main .debates .debate header ::after {
  content: ">";
  margin-inline-start: 1em;
}

.censorshipPage article main .debates .debate header img {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.censorshipPage article main .debates .debate header h1 {
  font-family: var(--ff-greta);
  font-size: var(--fs-500);
  font-weight: 600;
}

.censorshipPage article main .debates .debate main {
  margin: 1em 0.2em;
}
.censorshipPage article main .debates .debate main > .start {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5em;
  align-items: flex-end;
}
.censorshipPage article main .debates .debate main > .start img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.censorshipPage article main .debates .debate main > .start .lead {
  color: var(--color-green);
  font-weight: 500;
  font-family: var(--ff-david-vc);
  font-size: var(--fs-500);
  line-height: 1.3;
  margin-block-end: 2em;
}

.censorshipPage article main .debates .debate main > .start .lead .authorName {
  font-family: var(--ff-greta);
  margin-inline-end: 0.5em;
  font-size: 1.15em;
}

.censorshipPage article main .debates .debate .body {
  color: var(--color-black);
}

.censorshipPage article .polls {
  display: flex;
  flex-flow: column nowrap;
  gap: 2em;
  background-image: var(--polls-bg-img-url);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  position: relative;
  min-width: 20vw;
}

.borders.censorshipPage article .polls > h1 {
  font-family: var(--ff-discordia);
  font-style: normal;
  /* font-weight: 400; */
  font-feature-settings: normal;
}
  
.censorshipPage article .polls > h1 {
  font-family: var(--ff-kotz-display);
  font-weight: 600;
  font-style: italic;
  font-size: clamp(4em, max(9vw, 13vh), 40em);
  line-height: 0.9;
  color: var(--color-green);
  margin: 0.2em;
  /* font-size: 10vw; */
}
.censorshipPage article .polls > h1 .altGlyph {
  font-feature-settings: "ss01" 1;
}

.censorshipPage article .polls main {
  display: flex;
  flex-flow: column nowrap;
  gap: 1em;
  position: absolute;
  width: 100%;
  height: 100%;
}
.censorshipPage article .polls main section {
  background-color: var(--color-theme-foreground);
  color: var(--color-theme-background);
  border-radius: 8px;
  filter: drop-shadow(0 0 3px black);
  display: flex;
  flex-flow: column nowrap;
  width: 21ch;
  padding: 0.5em;
  position: absolute;
  gap: 0.5em;
  animation: floating var(--animation-duration, 5s) infinite ease-in-out
    var(--animation-delay, 0s);
  transform-origin: top left;
  transition: background-color 0.1s ease-out;
  transition-property: background-color, color;
}

.censorshipPage article .polls main section h1 {
  text-align: end;
  direction: ltr;
  font-size: var(--fs-400);
}

.censorshipPage article .polls main section p {
  font-weight: 700;
  line-height: 1.1;
  font-family: var(--ff-david-vc);
  font-weight: 700;
  font-size: var(--fs-500);
  margin: 0;
}

.censorshipPage article header {
  display: flex;
  flex-flow: column nowrap;
  gap: 4em;
}

.censorshipPage article .poll header {
  display: none;
}

.censorshipPage article header hgroup {
  display: flex;
  flex-flow: column nowrap;
  max-width: 99%;
  gap: 0em;
}
.censorshipPage article header hgroup h1 {
  font-family: var(--ff-kotz-display);
  /* font-feature-settings: "ss01"; */
  font-size: var(--fs-800);
  line-height: 0.9em;
  color: var(--color-green);
  margin-bottom: 0.2em;
}

.borders.censorshipPage article header hgroup h1 {
  color: #ff5c16;
  font-family: var(--ff-discordia);
  font-style: italic;
}

.censorshipPage article header hgroup h1.title .altGlyph {
  font-feature-settings: "ss01" 1;
}

.censorshipPage article header hgroup h2 {
  font-family: var(--ff-narkis-yair);
  font-size: 1.5rem;
  line-height: 1.7rem;

  max-width: 450px;
}

.censorshipPage article header hgroup h3 {
  font-family: var(--ff-narkis-yair);
  color: var(--color-green);
  font-size: 1.5rem;
}
.censorshipPage article header hgroup h3::before {
  content: ">";
  margin-inline-end: 0.5em;
}

.censorshipPage article header .lead {
  font: var(--font-kotz-500);
}

.censorshipPage article .bodyImage {
  max-width: calc(100% + 2em);
  margin: 2em 0;
  margin-inline-start: -2em;
  display: flex;
  flex-flow: column nowrap;
  width: max-content;
}
.censorshipPage article .bodyImage img {
  border-radius: 4px;
  width: 100%;
}

.censorshipPage article .bodyImage footer {
  color: var(--color-green);
  font-size: var(--fs-300);
  font-family: var(--ff-narkis-yair);
  text-align: left;
  margin-top: 3px;
  width: 100%;
}

.censorshipPage figure {
  display: flex;
  flex-flow: column nowrap;
}
.censorshipPage figure img {
  /* height: 17em; */
  /* width: auto; */
}

.censorshipPage figure figcaption {
  font-family: var(--ff-narkis-yair);
  font-size: var(--fs-300);
}

.censorshipPage main > .h1Spacer {
  height: 2em;
}

.censorshipPage main > h1 {
  margin-block: 2em 0.5em;
  color: var(--color-green);
  font-family: var(--ff-david-vc);
  font-style: italic;
  font-size: 1.58rem; /* var(--fs-600); */
  letter-spacing: 1px;
}

.borders.censorshipPage main > h1 {
  color: black;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0.5em;
  outline: 2px solid black;
  font-family: var(--ff-discordia);
  font-weight: 400;
  margin-inline: -3em;
  padding-inline-start: 3em;
  font-style: normal;
}

.borders.censorshipPage main > h2 {
  color: black;
  font-family: var(--ff-narkis-yair);
  font-size: 1.58rem; /* var(--fs-600); */
}

.borders.censorshipPage main > h2::before {
  content: "——————";
  padding-inline-end: 0.8em;
  font-weight: 700;
}

.censorshipPage main.interview h1 {
  line-height: 1em;
  font-family: var(--ff-greta);
  font-style: italic;
  font-weight: 600;
  display: inline;
  margin-inline-end: 0.5em;
  margin-block-start: 2em;
  margin-block-end: 0;
}

.borders.censorshipPage main.interview h1::before,
.borders.censorshipPage main.interview p::before {
  content: none !important;
}

.censorshipPage main.interview h1::before {
  content: "";
  background-image: url(../images/other/paragraph-vector.svg);
  background-size: contain;
  height: 2.5em;
  width: 2em;
  display: inline-block;
  background-repeat: no-repeat;
  margin-inline-end: 0.5em;
  float: inline-start;
  background-position: center center;
}

.censorshipPage main > p {
  font: var(--font-kotz-500);
  font-weight: 500;
  line-height: 1.77rem;
  letter-spacing: 0.7px;
  margin: 0;
}
.censorshipPage main > p:not(:first-child) {
  text-indent: var(--typo-indent);
}

.censorshipPage main > p b {
  color: #26bd6b;
}

.borders.censorshipPage main > p {
  font-family: var(--ff-borders-david);
}

.borders.censorshipPage main > p b {
  padding-block: 0.22em 0.1em;
  border: 2px solid black;
  color: black;
  padding-inline: 0.1em;
}

.censorshipPage main > p i {
  font-family: var(--ff-narkis-yair);
  font-size: 1.4rem;
}

.borders.censorshipPage main > p i {
  letter-spacing: 0.4em;
}

.borders.censorshipPage main.interview > p i {
  letter-spacing: 0;
}

.borders.censorshipPage main > p code {
  background-color: #e7b7a2;
}

.borders.censorshipPage main.interview > p s {
  background-color: #bbd682;
}

.borders.censorshipPage main.interview > p s,
.borders.censorshipPage main.interview > p code {
  outline: 1px solid black;
  margin-top: 0.3em;
  padding: 0em 0.2em;
  font-family: var(--ff-narkis-yair);
  display: inline-block;
  min-width: 7ch;
}

.borders.censorshipPage main > p u {
  text-decoration: underline;
  text-decoration-color: transparent;
  background: linear-gradient(to right, white 50%, red 50%);
  background-size: 5ch 2px;
  background-position: 0px 99%;
  background-repeat: repeat-x;
  padding-bottom: 0px;
  position: relative;
  z-index: 0;
  border-bottom: 2px solid black;
}

.borders.censorshipPage main > p u::before {
  display: none;
  content: "";
  position: absolute;
  left: 0;
  right: -1px;
  bottom: 0.6px;
  height: 5px;
  width: calc(100% + 2px);
  /* background-color: #000000; */
  /* border: 1px solid black; */
  z-index: -1;

  box-shadow: inset 0 -6px 0 #bf80f0;
}

.borders.censorshipPage main > p u::after {
  display: none;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1px; /* Adjust based on underline thickness */
  height: 2px; /* Height of the dashed line */
  background-image: linear-gradient(to right, black 50%, red 50%);
  background-size: 10px 2px; /* 5px for black, 5px for red */
  background-repeat: repeat-x;
  z-index: 1; /* Place above the border */
}

.censorshipPage main .interview p {
  text-indent: 0;
}

.borders.censorshipPage main .bodyImage + p {
  text-indent: 0;
}

.borders.censorshipPage main .interview p::before {
  content: none !important;
}

.censorshipPage main .interview p::before {
  content: "";
  display: inline-block;
  height: 0.7em;
  width: var(--typo-indent);
  background-color: var(--color-theme-foreground);
  margin-inline-end: 0.3em;
  transition: background-color 0.1s ease-out;
}

.censorshipPage main > h3 {
  font: var(--font-kotz-500);
  font-weight: 600;
  line-height: 1.3em;
  letter-spacing: 0.7px;
  margin: 0;
  color: #26bd6b;
  text-align: center;
  font-size: 1.6em;
}

.borders.censorshipPage main > h3 {
  color: black;
  outline: #ff5c16 dashed 3px;
  letter-spacing: 0;
  font-family: var(--ff-discordia);
  font-weight: 700;
  padding: 0.5em;
  margin: 1em;
  text-align: start;
}

.censorshipPage hr {
  border: none;
  border-top: 2px dotted var(--color-green);
  border-bottom: none;
  margin: 2em auto;
  width: 100%;
}

.censorshipPage .poll hr {
  display: none;
}
.censorshipPage + .h1Spacer {
  height: 0;
}

.censorshipPage footer {
  font-family: var(--ff-narkis-yair);
  font-size: 1.4rem;
}
.censorshipPage footer > main {
  display: flex;
  flex-flow: row nowrap;
  gap: 1em;
}
.censorshipPage footer > main > .imageContainer {
  flex: 1;
}
.censorshipPage footer > main > .imageContainer > img {
  border-radius: 50%;
}

.censorshipPage footer > main > .textBody {
  display: flex;
  flex-flow: column nowrap;
  flex: 4;
}
.censorshipPage footer > main > .textBody > h1 {
  font-family: inherit;
  font-weight: 700;
}

/* Landscape */
@media (min-aspect-ratio: 1), (orientation: landscape) {
  .censorshipPage {
    position: static;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
  }

  .censorshipPage article > main .debates > .debate > main {
    margin: 1em 1.5em;
  }
}

/* Mobile */
@media (max-aspect-ratio: 1), (orientation: portrait) {
  .censorshipPage article {
    padding: 1em;
    padding-top: 4em;
    padding-inline-end: 0;
  }
  .censorshipPage article > main {
    max-width: 60ch;
  }

  .censorshipPage article .poll main {
    max-width: none;
  }

  .censorshipPage footer > main {
    flex-direction: column;
  }
  .censorshipPage footer > main .imageContainer > img {
    max-width: 30%;
  }
}

/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */
/* NEW CSS */

/* FakePage.module.css */
.censorshipPage {
  width: 100vw;
  width: 100dvw;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  /* display: grid;
    
    grid-template-columns: repeat(6, 1fr); */
}

/* Mobile */
@media (max-aspect-ratio: 1) {
  .polls {
    & > h1 {
      padding-block-start: 1.5em;
    }
  }
}

.imageBar {
  display: flex;
  flex-flow: column nowrap;
  /* width: 1fr; */
  overflow: hidden;
  /* height: 60px; */
  width: 100%;
  position: relative;
  /* transition: all 2s ease-out; */
}

.imageColumn {
  /* flex: none; */
}

.articleContainer {
  display: flex;
  flex-flow: column nowrap;
  /* height: 100vh; */
  width: 100%;
  justify-content: center;
  /* width: 20px; */
  align-items: center;
  /* position: absolute; */
  max-height: 1px;
  /* top: 0; */
  overflow: hidden;
  /* transition: all 2s ease-out; */
}

.show .articleContainer {
  max-height: 300px;
  justify-content: flex-start;
  flex-shrink: 0;
}

.headerImage {
  width: 100%;
  height: 120px;
  /* Additional styles */
  flex-shrink: 0;
}

.imageImg {
  width: 100%;
  /* max-height: 120px; */
}

.imgCont {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* max-height: 100px; */
}

.show .imgCont {
  height: 80px;
}

.show.imageBar {
  flex-shrink: 0;
}

@keyframes floating {
  0% {
    transform: translate(
      var(--translate-x-start, 0px),
      var(--translate-y-start, 0px)
    );
  }
  50% {
    transform: translate(
      var(--translate-x-end, 0px),
      var(--translate-y-end, 0px)
    );
  }
  100% {
    transform: translate(
      var(--translate-x-start, 0px),
      var(--translate-y-start, 0px)
    );
  }
}

.censorshipPage {
  & .songs {
    display: flex;
    flex-flow: column nowrap;
    background-color: var(--color-theme-foreground);
    color: var(--color-theme-background);
    font-family: var(--ff-narkis-yair);
    padding: 0 2em;

    margin: 2em 0;
    margin-inline-start: -2em;
    position: relative;
    overflow: auto;
    max-height: 40vh;
    width: 100%;

    background-image: url(../images/other/song_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    transition: background-color 0.1s ease-out;
    transition-property: background-color, color;

    & .borderContainer {
      display: flex;
      flex-flow: column nowrap;
      gap: 2em;
      padding: 2em 1em;
      padding-top: 0;
    }

    & h1 {
      font-family: var(--ff-hadassa);
      font-weight: 900;
      font-style: normal;
      color: var(--color-black);
      margin: 0;
    }

    & section {
      display: flex;
      flex-flow: column nowrap;
      gap: 1em;
      line-height: 1;
      & .song {
        & .separator {
          margin-block-end: 0.5em;
        }

        & p {
          line-height: 1;
          & + p {
            margin-block-start: 0.2em;
          }

          & + .separator {
            margin-block-start: 2em;
          }

          .separator + & {
            &::before {
              content: "א";
              position: absolute;
              right: 10%;
              font-family: var(--ff-hadassa);
              font-size: 0.9em;
              padding-top: 0.15em;
            }
          }
        }
      }
    }
  }

  & main .terms {
    display: flex;
    flex-flow: column nowrap;
    background-color: var(--color-dark-green);
    color: var(--color-black);
    font-family: var(--ff-hadassa);
    padding: 0 2em;

    margin: 2em 0;
    margin-inline-start: -2em;
    position: relative;
    overflow: auto;
    max-height: 40vh;
    width: 100%;

    .interview & {
      & h1::before {
        content: "";
        display: none;
      }
    }

    &.questionnaire {
      background-color: var(--color-theme-foreground);
      color: var(--color-theme-background);
      max-height: 80vh;
    }

    & .borderContainer {
      display: flex;
      flex-flow: column nowrap;
      gap: 2em;
      border: none;
      border-right: 2px dotted black;
      padding: 2em 1em;
      padding-top: 0;
      margin-top: 2em;
    }

    & h1 {
      font-family: var(--ff-hadassa);
      font-weight: 900;
      font-size: 1.5rem;
      font-style: normal;
      color: var(--color-black);
      margin: 0;
    }

    & section {
      display: flex;
      flex-flow: column nowrap;
      gap: 1em;
      line-height: 1;
      & .term {
        & h2 {
          font-size: 1.5rem;
          font-weight: 900;
          display: inline;
          line-height: 1;
          margin-inline-end: 0.5em;
        }

        & p {
          display: inline;
          font-family: "Narkissim";
          font-weight: 300;
          font-size: 1.25rem;
          line-height: 1;

          & > u {
            text-decoration: 2px dotted underline;
            text-underline-offset: 0.2em;
          }
        }
      }
    }
  }

  & article {
    max-width: 100%;
    padding: 2em;
    color: var(--color-theme-foreground);
    display: flex;
    flex-flow: column nowrap;
    gap: 2em;
    padding-top: 4em;
    cursor: default;
    min-height: 100vh;
    min-height: 100dvh;
    background-color: var(--color-theme-background);
    transition: background-color 0.1s ease-out;
    transition-property: background-color, color;
    margin-block-end: 8em;

    &.poll {
      padding: 0;
      margin-block-end: 0;

      & > main {
        height: 100vh;
        height: 100dvh;
        overflow: hidden;

        & > .polls {
          height: 100%;
        }
      }
    }

    & main {
      & .debates {
        position: relative;
        display: flex;
        /* margin-inline: -0.2em; */

        & .debate {
          position: absolute;
          background-color: var(--color-white);
          color: var(--color-black);
          border-radius: 8px;
          display: flex;
          flex-flow: column nowrap;
          width: 85%;
          background-color: var(--color-white);
          transition: all 0.15s ease-out;
          top: 0;
          right: 0;
          filter: drop-shadow(0 0 3px black);
          padding: 0.2em 1em;
          gap: 1em;

          &.active {
            z-index: 1;

            & header {
              & ::after {
                content: "<";
              }
            }

            &:active {
              transform: translate(-4px, -4px);
            }
          }

          &:not(.active) {
            top: 0;
            right: 150px;

            &:hover {
              transform: translate(-4px, -4px);
            }

            &:active {
              transform: translate(-8px, -8px);
            }
          }

          &:nth-child(odd) {
            background-color: rgb(203, 203, 203);
          }

          & header {
            display: flex;
            flex-flow: row nowrap;
            justify-content: end;
            gap: 0.5em;
            align-items: center;
            border: none;
            border-bottom: 2px dotted var(--color-black);
            padding-block: 0.3em 0.4em;

            & ::after {
              content: ">";
              margin-inline-start: 1em;
            }

            & img {
              height: 20px;
              width: 20px;
              border-radius: 50%;
            }

            & h1 {
              font-family: var(--ff-greta);
              font-size: var(--fs-500);
              font-weight: 600;
            }
          }

          & main {
            margin: 1em 0.2em;

            & > .start {
              display: flex;
              flex-flow: column nowrap;
              gap: 0.5em;
              align-items: flex-end;

              & img {
                height: 80px;
                width: 80px;
                border-radius: 50%;
              }

              & .lead {
                color: var(--color-green);
                font-weight: 500;
                font-family: var(--ff-david-vc);
                font-size: var(--fs-500);
                line-height: 1.3;
                margin-block-end: 2em;

                & .authorName {
                  font-family: var(--ff-greta);
                  margin-inline-end: 0.5em;
                  font-size: 1.15em;
                }
              }
            }

            & .body {
              color: var(--color-black);
            }
          }
        }
      }
    }

    & .polls {
      display: flex;
      flex-flow: column nowrap;
      gap: 2em;
      background-image: var(--polls-bg-img-url);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;

      position: relative;
      min-width: 20vw;

      & > h1 {
        font-family: var(--ff-kotz-display);
        font-weight: 600;
        font-style: italic;
        font-size: clamp(4em, max(9vw, 13vh), 40em);
        line-height: 0.9;
        color: var(--color-green);
        margin: 0.2em;
        /* font-size: 10vw; */
        & .altGlyph {
          font-feature-settings: "ss01" 1;
        }
      }

      & main {
        display: flex;
        flex-flow: column nowrap;
        gap: 1em;
        position: absolute;
        width: 100%;
        height: 100%;

        & section {
          background-color: var(--color-theme-foreground);
          color: var(--color-theme-background);
          border-radius: 8px;
          filter: drop-shadow(0 0 3px black);
          display: flex;
          flex-flow: column nowrap;
          width: 21ch;
          padding: 0.5em;
          position: absolute;
          gap: 0.5em;
          animation: floating var(--animation-duration, 5s) infinite ease-in-out
            var(--animation-delay, 0s);
          transform-origin: top left;
          transition: background-color 0.1s ease-out;
          transition-property: background-color, color;

          & h1 {
            text-align: end;
            direction: ltr;
            font-size: var(--fs-400);
          }

          & p {
            font-weight: 700;
            line-height: 1.1;
            font-family: var(--ff-david-vc);
            font-weight: 700;
            font-size: var(--fs-500);
            margin: 0;
          }
        }
      }
    }

    & header {
      display: flex;
      flex-flow: column nowrap;
      gap: 4em;

      .poll & {
        display: none;
      }

      & hgroup {
        display: flex;
        flex-flow: column nowrap;
        max-width: 99%;
        gap: 0em;

        & h1 {
          font-family: var(--ff-kotz-display);
          /* font-feature-settings: "ss01"; */
          font-size: var(--fs-800);
          line-height: 0.9em;
          color: var(--color-green);
          margin-bottom: 0.2em;

          &.title {
            & .altGlyph {
              font-feature-settings: "ss01" 1;
            }
          }
        }

        & h2 {
          font-family: var(--ff-narkis-yair);
          font-size: 1.5rem;
          line-height: 1.7rem;

          max-width: 450px;
        }

        & h3 {
          font-family: var(--ff-narkis-yair);
          color: var(--color-green);
          font-size: 1.5rem;

          &::before {
            content: ">";
            margin-inline-end: 0.5em;
          }
        }
      }

      & .lead {
        font: var(--font-kotz-500);
      }
    }

    & .bodyImage {
      max-width: calc(100% + 2em);
      margin: 2em 0;
      margin-inline-start: -2em;
      display: flex;
      flex-flow: column nowrap;
      width: max-content;

      & img {
        border-radius: 4px;
        width: 100%;
      }

      & footer {
        color: var(--color-green);
        font-size: var(--fs-300);
        font-family: var(--ff-narkis-yair);
        text-align: left;
        margin-top: 3px;
        width: 100%;

        .term & {
          color: var(--color-theme-foreground);
        }

        .terms.questionnaire & {
          color: var(--color-theme-background);
        }
      }
    }
  }
}

.censorshipPage figure {
  display: flex;
  flex-flow: column nowrap;
}
.censorshipPage figure img {
  /* height: 17em; */
  /* width: auto; */
}

.censorshipPage figure figcaption {
  font-family: var(--ff-narkis-yair);
  font-size: var(--fs-300);
}

.censorshipPage {
  & main {
    & > .h1Spacer {
      height: 2em;
    }

    & > h1 {
      margin-block: 2em 0.5em;
      color: var(--color-green);
      font-family: var(--ff-david-vc);
      font-style: italic;
      font-size: 1.58rem; /* var(--fs-600); */
      letter-spacing: 1px;

      .interview & {
        line-height: 1em;
        font-family: var(--ff-greta);
        font-style: italic;
        font-weight: 600;
        display: inline;
        margin-inline-end: 0.5em;
        margin-block-start: 2em;
        margin-block-end: 0;

        &::before {
          content: "";
          background-image: url(../images/other/paragraph-vector.svg);
          background-size: contain;
          height: 2.5em;
          width: 2em;
          display: inline-block;
          background-repeat: no-repeat;
          margin-inline-end: 0.5em;
          float: inline-start;
          background-position: center center;
        }

        + p & {
          margin-block-end: 2em;
        }
      }
    }

    & > p {
      font: var(--font-kotz-500);
      font-weight: 500;
      line-height: 1.77rem;
      letter-spacing: 0.7px;
      margin: 0;

      &:not(:first-child) {
        text-indent: var(--typo-indent);
      }

      h1 + &,
      h2 + &,
      .terms + & {
        text-indent: 0 !important;
      }

      & b {
        color: #26bd6b;
      }

      & i {
        font-family: var(--ff-narkis-yair);
        font-size: 1.4rem;
      }

      .interview > & {
        text-indent: 0;

        h1 + & {
          display: inline;

          &::before {
            content: none;
          }
        }

        &:first-child,
        div + &,
        hr + &,
        h1 + & {
          &::before {
            content: none;
          }
        }

        &::before {
          content: "";
          display: inline-block;
          height: 0.7em;
          width: var(--typo-indent);
          background-color: var(--color-theme-foreground);
          margin-inline-end: 0.3em;
          transition: background-color 0.1s ease-out;
        }
      }
    }

    & > h3 {
      font: var(--font-kotz-500);
      font-weight: 600;
      line-height: 1.3em;
      letter-spacing: 0.7px;
      margin: 0;
      color: #26bd6b;
      text-align: center;
      font-size: 1.6em;
    }
  }

  & hr {
    border: none;
    border-top: 2px dotted var(--color-green);
    border-bottom: none;
    margin: 2em auto;
    width: 100%;

    + .h1Spacer {
      height: 0;
    }
  }

  & footer {
    font-family: var(--ff-narkis-yair);
    font-size: 1.4rem;

    & > main {
      display: flex;
      flex-flow: row nowrap;
      gap: 1em;

      & > .imageContainer {
        flex: 1;

        & > img {
          border-radius: 50%;
        }
      }

      & > .textBody {
        display: flex;
        flex-flow: column nowrap;
        flex: 4;
        & > h1 {
          font-family: inherit;
          font-weight: 700;
        }
      }
    }
  }
}
/* Landscape */
@media (min-aspect-ratio: 1) {
  .censorshipPage {
    position: static;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    & article {
      & > main {
        & .debates {
          & > .debate {
            & > main {
              margin: 1em 1.5em;
            }
          }
        }
      }
    }
  }
}

/* Mobile */
@media (max-aspect-ratio: 1) {
  .censorshipPage {
    & article {
      padding: 1em;
      padding-top: 4em;
      padding-inline-end: 0;

      & > main {
        max-width: 60ch;

        .poll & {
          max-width: none;
        }
        /* padding-left: 13vw; */
      }
    }

    & footer {
      & > main {
        flex-direction: column;

        & .imageContainer {
          & > img {
            max-width: 30%;
          }
        }
      }
    }
  }
}
