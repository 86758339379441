/* Sidebar.module.css */

.outer {
  display: flex;
  height: calc(100% - var(--ticker-height));
  flex: 1;
}

.dayafter.outer {
}

.dayafter.outer > .homepageImages {
  display: none;
}

.outer > .homepageImages {
  position: absolute;
  left: 0;
  width: 60vw;
  height: 100vh;
  pointer-events: none;
}

.outer > .homepageImages.isOpen > .homepageImage {
  scale: 0;
  opacity: 0;
}

.outer > .homepageImages > .homepageImage {
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.4));
  width: clamp(80px, 20vw, 250px);
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  animation: floating var(--animation-duration, 5s) infinite ease-in-out
    var(--animation-delay, 0s);
  transform-origin: center center;
  transition: all 0.2s ease-out;
  top: var(--top);
  left: var(--left);
}

.dayafter.outer > .nav {
  font-family: var(--ff-dayafter);
  overflow: auto;
}

.dayafter.outer > .nav .navItem.bottomNav {
  font-size: 36px;
  position: sticky !important;
  bottom: 0;
}

.dayafter.outer > .nav .navItem.bottomNav .issueName {
  font-size: 28px;
  color: white;
  font-family: "DavidKotz";
  font-feature-settings: "ss01" 1;
  margin-inline-end: 12px;
}

.dayafter.outer > .nav .navItem.bottomNav .issueDetails {
  font-family: var(--ff-david-vc);
  font-weight: 700;
  font-size: 24px;
}

/* 
.dayafter.outer > .nav::after {
  content: "hello!";
  width: 100%;
  height: 30px;
  color: var(--active-theme-color);
  
} */

.dayafter.outer > .nav {
  gap: 0;
  padding: 0;
}

.outer > .nav {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  font-family: var(--ff-leon);
  gap: var(--border-size);
  background-color: black;
  padding: var(--border-size);
  padding-inline-end: 0;
  overflow: hidden; /* Add this line */
}

.dayafter.outer > .nav .navItem {
  font-size: 100px;
}
  
.outer > .nav .navItem {
  background-color: var(--color-theme-background);
  cursor: pointer;
  font-size: 28px;
  color: #333;
  flex-grow: 1;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  color: black;
  transition: all 0.1s ease-out;
  padding: 0 !important;
  padding-inline-start: 10px !important;
  padding-block-end: 0 !important;
  position: relative !important;
  overflow: visible !important;
  /* width: fit-content !important; */
}

.outer > .nav .navItem .navContentContainer {
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
}

.dayafter.outer > .nav .navItem > .navTag {
  display: none;
}

.outer > .nav .navItem > .navTag {
  opacity: 0;
  scale: 0.5;
  position: absolute;
  transition: all 0.1s ease-out;
  right: 0;
  pointer-events: none;
  left: 0 !important;
  right: auto !important;
  z-index: 1;
}

.outer > .nav .navItem:hover > .navTag {
  opacity: 1;
  scale: 1;
  position: absolute;
  transition: all 0.1s ease-out;
  right: 16px;
}

.dayafter.outer > .nav .navItem:hover {
  color: var(--active-theme-color);
}
  
.outer > .nav .navItem:hover {
  color: var(--theme-color);
}

.dayafter.outer > .nav .navItem {
  color: var(--active-theme-color);
  background-color: var(--active-alt-color);
}

.dayafter.outer > .nav .navItem.activeNavItem {
  color: transparent;
}

.outer > .nav .navItem:hover.activeNavItem {
  color: transparent;
}

.outer > .nav .navItem.activeNavItem {
  background-color: var(--theme-color);
}

.outer > .nav .navItem.activeNavItem .navContent {
  display: inline-block;
  /* padding-top: 100%; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.outer > .nav .navItem .navContent:hover {
  animation: navAnimation 3s ease-in infinite; /* Add animation */
}

.dayafter.outer > .articles {
  padding: 0;
  gap: 0;
}
  
.outer > .articles {
  display: flex;
  flex-direction: row-reverse;
  flex: 3;
  gap: var(--border-size);
  background-color: black;
  padding: var(--border-size);
  width: 100%;
  overflow: hidden;
}

.outer > .articles:empty {
  display: none;
}

.outer > .articles > .articleOuter {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
}

.outer > .articles > .articleOuter.popout {
  z-index: 20;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-block: 7vh;
  margin-inline: 7vw;
  outline: var(--border-size) solid black;
  justify-content: center;
  align-items: center;
  display: none;
  min-width: auto;
}

.outer > .articles > .articleOuter.popout.active {
  display: unset;
}

.dayafter.outer > .articles > .articleOuter .topBar .xButton {
  display: none;
}
  
.outer > .articles > .articleOuter.popout > .topBar > .xButton {
  display: flex;
}

.outer > .articles > .articleOuter.notActive {
  display: none;
}

.dayafter.outer > .articles > .articleOuter > .topBar {
  outline: none;
}
  
.outer > .articles > .articleOuter > .topBar {
  position: sticky;
  top: 0;
  height: clamp(20px, 5dvh, 40px);
  background-color: var(--theme-color);
  outline: var(--border-size) solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.dayafter.outer > .articles > .articleOuter > .topBar .title {
  font-family: var(--ff-david-vc);
  font-weight: 700;
}
  
.outer > .articles > .articleOuter > .topBar .title {
  padding-inline: 10px;
  flex: 1;
  text-align: center;
  font-weight: bold;
  color: white;
  opacity: 0;
  scale: 0.5;
  transition: all 0.1s ease-in-out;
  font-family: var(--ff-leon);
  font-size: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.outer > .articles > .articleOuter > .topBar > .xButton {
  height: 100%;
  background-color: white;
  outline: var(--border-size) solid black;
  aspect-ratio: 1;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;
}

.outer > .articles > .articleOuter > .topBar > .xButton::before,
.outer > .articles > .articleOuter > .topBar > .xButton::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 4px; /* Cross thickness */
  background-color: black;
}

.outer > .articles > .articleOuter > .topBar > .xButton::before {
  transform: rotate(45deg);
}

.outer > .articles > .articleOuter > .topBar > .xButton::after {
  transform: rotate(-45deg);
}

.outer > .articles > .articleOuter.scrolled .topBar .title {
  opacity: 1;
  scale: 1;
}

.outer > .articles > .articleOuter > article {
  height: 100%;
  overflow: auto;
  padding-block-end: 8em;
}

.dayafter.outer > .articles > .articleOuter > article {
  padding: 0;
  margin-top: -40px;
}

.outer > .articles .articleIsNotOpen ~ .toTop {
  display: none;
}

.outer > .articles > .toTop {
  position: absolute;
  z-index: 3;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 20px auto;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-theme-foreground);
  box-shadow: 0px 1px 8px 2px rgb(0 0 0 / 3%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  pointer-events: none;
}

.outer > .articles > .toTop > .topArrow {
  filter: none;
  margin-block-end: 2px;
  filter: drop-shadow(0 0 3px black);
}

.outer > .articles > .toTop.scrolled {
  opacity: 0.5;
  cursor: pointer;
  pointer-events: all;
}

@keyframes navAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Landscape */
@media (min-aspect-ratio: 1), (orientation: landscape) {
  .outer {
    flex-direction: row;
  }

  .outer > .articles {
    flex-direction: row;
    flex: var(--open-articles) !important;
  }

  .outer > .nav {
    min-width: 100px;
    overflow: hidden;
  }

  .outer .articles.isNotOpen {
    display: none;
  }
}

/* Portrait */
@media (max-aspect-ratio: 1), (orientation: portrait) {
  .outer > .homepageImages {
    display: none;
  }

  .outer {
    --sidebar-max-width: 10vw;
  }

  .dayafter.outer .nav {
    padding-block-end: 0;
    outline: none;
  }
    
  .outer .nav {
    position: fixed !important;
    z-index: 1 !important;
    flex: none;
    max-width: var(--sidebar-max-width);
    transition: all 0.2s ease-in !important;
    padding-inline-end: var(--border-size);
    padding-inline-end: 0 !important;
    outline: var(--border-size) solid black;
    padding-block-end: calc(
      var(--ticker-height) + var(--border-size)
    ) !important;
    --touch-max-width: var(--sidebar-max-width);
  }

  
.dayafter.outer > .nav .navItem.bottomNav {
  overflow: hidden !important;
}

  .dayafter.outer .nav {
    /* padding-block-end: 0 !important; */
  }

  .noTouch .nav.temporaryClosed {
    max-width: var(--sidebar-max-width) !important;
  }

  .outer .nav:hover {
    transition: all 0.2s ease-out !important;
  }

  .touch .outer .nav {
    min-width: var(--sidebar-max-width) !important; /* Adjust as needed */
    transition: all 0.2s cubic-bezier(0.6, -0.28, 0.74, 0.05) !important;
  }

  .touch .outer .nav:hover {
    max-width: var(--sidebar-max-width) !important; /* Adjust as needed */
  }

  .touch .outer .nav:active {
    max-width: var(--sidebar-max-width) !important; /* Adjust as needed */
  }

  .touch .hamburgerClicked .outer .nav:hover {
    max-width: 85vw !important; /* Adjust as needed */
  }

  .touch .hamburgerClicked .outer .nav:active {
    max-width: 85vw !important; /* Adjust as needed */
  }

  .hamburgerClicked .outer .nav {
    max-width: 85vw !important; /* Adjust as needed */
    /* transition: none !important; */
  }

  .outer .nav .navItem {
    /* display: block; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .outer > .nav > .navItem > .navTag {
    display: none;
  }

  .outer .articles {
    /* position: sticky !important; */
    margin-right: var(--sidebar-max-width) !important;
    flex-direction: row;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding-inline-start: 0;
  }

  .outer .articles .articleOuter {
    min-width: 100%;
    scroll-snap-align: start;
  }

  .outer .articles .articleOuter > .topBar > .xButton {
    display: none;
  }
}

@keyframes floating {
  0% {
    transform: translate(
      var(--translate-x-start, 0px),
      var(--translate-y-start, 0px)
    );
  }
  50% {
    transform: translate(
      var(--translate-x-end, 0px),
      var(--translate-y-end, 0px)
    );
  }
  100% {
    transform: translate(
      var(--translate-x-start, 0px),
      var(--translate-y-start, 0px)
    );
  }
}

/* NEW */
/* NEW */
/* NEW */
/* NEW */
/* NEW */
/* NEW */
/* NEW */
/* NEW */
/* NEW */
/* NEW */
/* NEW */
/* NEW */
/* NEW */
/* NEW */
/* NEW */
/* NEW */
/* NEW */

/* Sidebar.module.css */

.outer {
  display: flex;
  height: calc(100% - var(--ticker-height));
  flex: 1;

  & > .homepageImages {
    position: absolute;
    left: 0;
    width: 60vw;
    height: 100vh;
    pointer-events: none;

    &.isOpen {
      & > .homepageImage {
        scale: 0;
        opacity: 0;
      }
    }

    & > .homepageImage {
      filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.4));
      width: clamp(80px, 20vw, 250px);
      display: flex;
      flex-flow: column nowrap;
      position: absolute;
      animation: floating var(--animation-duration, 5s) infinite ease-in-out
        var(--animation-delay, 0s);
      transform-origin: center center;
      transition: all 0.2s ease-out;
      top: var(--top);
      left: var(--left);
      z-index: 1;
    }
  }

  & > .nav {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
    font-family: var(--ff-leon);
    gap: var(--border-size);
    background-color: black;
    padding: var(--border-size);
    padding-inline-end: 0;
    overflow: hidden; /* Add this line */

    & .navItem {
      background-color: var(--color-theme-background);
      cursor: pointer;
      padding: 10px;
      font-size: 28px;
      color: #333;
      flex-grow: 1;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      color: black;
      transition: all 0.1s ease-out;
      padding-block-end: 16px;

      & > .navTag {
        opacity: 0;
        scale: 0.5;
        position: absolute;
        transition: all 0.1s ease-out;
        right: 0;
        pointer-events: none;
      }

      &:hover {
        & > .navTag {
          opacity: 1;
          scale: 1;
          position: absolute;
          transition: all 0.1s ease-out;
          right: 16px;
        }
      }

      &:hover {
        color: var(--theme-color);

      }

      &.activeNavItem {
        background-color: var(--theme-color);

        & .navContent {
          display: inline-block;
          /* padding-top: 100%; */
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: start;
          &:hover {
            animation: navAnimation 3s ease-in infinite; /* Add animation */
          }
        }
      }
    }
  }

  & > .articles {
    display: flex;
    flex-direction: row-reverse;
    flex: 3;
    gap: var(--border-size);
    background-color: black;
    padding: var(--border-size);
    width: 100%;
    overflow: hidden;

    &:empty {
      display: none;
    }

    & > .articleOuter {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-flow: column nowrap;

      &.popout {
        z-index: 20;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin-block: 7vh;
        margin-inline: 7vw;
        outline: var(--border-size) solid black;
        justify-content: center;
        align-items: center;
        display: none;
        min-width: auto;

        &.active {
          display: unset;
        }

        & > .topBar {
          & > .xButton {
            display: flex;
          }
        }
      }

      &.notActive {
        display: none;
      }

      & > .topBar {
        position: sticky;
        top: 0;
        height: clamp(20px, 5dvh, 40px);
        background-color: var(--theme-color);
        outline: var(--border-size) solid black;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;

        & .title {
          padding-inline: 10px;
          flex: 1;
          text-align: center;
          font-weight: bold;
          color: white;
          opacity: 0;
          scale: 0.5;
          transition: all 0.1s ease-in-out;
          font-family: var(--ff-leon);
          font-size: 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        & > .xButton {
          height: 100%;
          background-color: white;
          outline: var(--border-size) solid black;
          aspect-ratio: 1;
          cursor: pointer;
          display: flex;
          flex-flow: column;
          justify-content: center;
          position: relative;

          &::before,
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 4px; /* Cross thickness */
            background-color: black;
          }

          &::before {
            transform: rotate(45deg);
          }

          &::after {
            transform: rotate(-45deg);
          }
        }
      }

      &.scrolled .topBar .title {
        opacity: 1;
        scale: 1;
      }

      & > article {
        height: 100%;
        overflow: auto;
        padding-block-end: 8em;
      }
    }

    & .articleIsNotOpen ~ .toTop {
      display: none;
    }

    & > .toTop {
      position: absolute;
      z-index: 3;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 20px auto;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--color-theme-foreground);
      box-shadow: 0px 1px 8px 2px rgb(0 0 0 / 3%);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      opacity: 0;
      transition: all 0.1s ease-in-out;
      pointer-events: none;

      & > .topArrow {
        filter: none;
        margin-block-end: 2px;
        filter: drop-shadow(0 0 3px black);
      }

      &.scrolled {
        opacity: 0.5;
        cursor: pointer;
        pointer-events: all;
      }
    }
  }
}

@keyframes navAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Landscape */
@media (min-aspect-ratio: 1), (orientation: landscape) {
  .outer {
    flex-direction: row;

    & > .articles {
      flex-direction: row;
    }

    & > .nav {
      min-width: 100px;
      overflow: hidden;
    }
  }
}

/* Portrait */
@media (max-aspect-ratio: 1), (orientation: portrait) {
  .outer {
    & > .homepageImages {
      display: none;
    }

    .nav {
      flex: none;
      max-width: 10vw;
      transition: all 0.3s ease-in-out;
      padding-inline-end: var(--border-size);
      outline: var(--border-size) solid black;
      &:hover {
        max-width: 85vw; /* Adjust as needed */
      }

      .navItem {
        /* display: block; */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .articles {
      flex-direction: row;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      padding-inline-start: 0;

      .articleOuter {
        min-width: 100%;
        scroll-snap-align: start;

        & > .topBar {
          & > .xButton {
            display: none;
          }
        }
      }
    }
  }
}

@keyframes floating {
  0% {
    transform: translate(
      var(--translate-x-start, 0px),
      var(--translate-y-start, 0px)
    );
  }
  50% {
    transform: translate(
      var(--translate-x-end, 0px),
      var(--translate-y-end, 0px)
    );
  }
  100% {
    transform: translate(
      var(--translate-x-start, 0px),
      var(--translate-y-start, 0px)
    );
  }
}
