:root {
  --typeface-serif: "DavidVC";
  --typeface-sans: "NarkisYair";
  --font-running: 18px/25px var(--typeface-serif);
  /* --font-item-h1: 18px/25px var(--typeface-serif); */

  --ff-david-vc: "DavidVC", "NarkisYair";
  --ff-discordia: "Discordia";
  --ff-kotz-text: "KotzText";
  --ff-kotz-display: "KotzDisplay";
  --ff-narkis-yair: "NarkisYair";
  --ff-hadassa: "Hadassa";
  --ff-greta: "Greta";
  --ff-leon: "Leon";
  --ff-haim: "Haim";
  --ff-haim-cond: "HaimCondensed";
  --ff-futura: "Futura";
  --ff-frankg: "FrankG";
  --ff-dayafter: "Dayafter";
  --ff-borders-david: "BordersDavid";

  --fs-xl: clamp(3.5rem, 12vw + 1rem, 12rem);
  --fs-800: 9rem;
  --fs-700: 3rem;
  --fs-600: 1.5rem;
  --fs-500: 1.25rem;
  --fs-400: 1.15rem;
  --fs-300: 0.9rem;

  --font-david-500: var(--fs-500) / 1.6 var(--ff-david-vc);
  --font-kotz-500: var(--fs-500) / 1.6 var(--ff-david-vc);

  --color-white: whitesmoke;
  --color-dark-black: #222;
  --color-black: #2b2b2b;
  --color-green: #27b769;
  --color-dark-green: #43885b;

  --typo-indent: 3em;

  --ticker-height: 48px;
  --border-size: 3px;

  font-size: 15px;

  --color-theme-background: var(--color-dark-black);
  --color-theme-foreground: var(--color-white);

  --kotz-icon-opacity: 1;
}

[data-theme="light"] {
  --color-theme-background: var(--color-white);
  --color-theme-foreground: var(--color-dark-black);
}

[kotz-icon-is-hiding="true"] {
  --kotz-icon-opacity: 0.05;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (max-width: 700px) {
  :root {
    font-size: 13px;
  }
}

/* Mobile */
@media (max-aspect-ratio: 1), (orientation: portrait) {
  :root {
    font-size: 14px;
    --fs-800: 5rem;
  }
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
  color: inherit;
  text-decoration: inherit;
}

img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}

html {
  color-scheme: dark light;
  hanging-punctuation: first last;
  font-size: 16px;
  direction: rtl;
}

body {
  min-height: 100vh;
  min-height: 100dvh;
  font-size: var(--fs-400);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-wrap: balance;
}

p {
  /* max-width: 55ch; */
  text-wrap: pretty;
}

@media (prefers-reduced-motion: no-preference) {
  :has(:target) {
    scroll-behavior: smooth;
    scroll-padding-top: 3rem;
  }
}

.title {
  font-size: 5rem;
}

.subtitle {
  font-size: 3rem;
}

#root {
  min-height: 100vh;
  min-height: 100dvh;
}

/* Portrait */
@media (max-aspect-ratio: 1) {
  :root {
    --ticker-height: 38px;
  }
}
