/* FakePage.module.css */
.bodyImageWrapper {
  position: fixed;
  top: 50%;
  transform: translateY(-30%);
  max-width: 50vw;
  right: 87ch;
  width: 38vw;
  left: 0;
  pointer-events: none;
}

@media (max-width: 1500px) {
  .bodyImageWrapper {
    display: none;
  }
}

.fakePage.isOpen {
  background-color: orange;
}

.alonalonalon {
  height: 100%;
}

.fakePage {
  width: 100vw;
  width: 100dvw;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.fakePage article > main {
  padding: 24px;
  max-width: 80ch;
}

/* Mobile */

.imageBar {
  display: flex;
  flex-flow: column nowrap;
  /* width: 1fr; */
  overflow: hidden;
  /* height: 60px; */
  width: 100%;
  position: relative;
  /* transition: all 2s ease-out; */
}

.articleContainer {
  display: flex;
  flex-flow: column nowrap;
  /* height: 100vh; */
  width: 100%;
  justify-content: center;
  /* width: 20px; */
  align-items: center;
  /* position: absolute; */
  max-height: 1px;
  /* top: 0; */
  overflow: hidden;
  /* transition: all 2s ease-out; */
}

.show .articleContainer {
  max-height: 300px;
  justify-content: flex-start;
  flex-shrink: 0;
}

.headerImage {
  width: 100%;
  height: 120px;
  /* Additional styles */
  flex-shrink: 0;
}

.imageImg {
  width: 100%;
  /* max-height: 120px; */
}

.imgCont {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* max-height: 100px; */
}

.show .imgCont {
  height: 80px;
}

.show.imageBar {
  flex-shrink: 0;
}

@keyframes floating {
  0% {
    transform: translate(
      var(--translate-x-start, 0px),
      var(--translate-y-start, 0px)
    );
  }
  50% {
    transform: translate(
      var(--translate-x-end, 0px),
      var(--translate-y-end, 0px)
    );
  }
  100% {
    transform: translate(
      var(--translate-x-start, 0px),
      var(--translate-y-start, 0px)
    );
  }
}

.fakePage .terms {
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--theme-color);
  color: var(--color-black);
  font-family: var(--ff-hadassa);
  padding: 0 2em;

  margin: 2em 0;
  margin-inline-start: -2em;
  position: relative;
  overflow: auto;
  max-height: 40vh;
  width: 100%;
  z-index: 0;
}
.fakePage .terms .borderContainer {
  display: flex;
  flex-flow: column nowrap;
  gap: 2em;
  border: none;
  border-right: 2px dotted black;
  padding: 2em 1em;
  padding-top: 0;
  margin-top: 2em;
}

.fakePage .terms h1 {
  font-family: var(--ff-hadassa);
  font-weight: 900;
  font-size: 1.5rem;
  font-style: normal;
  color: var(--color-black);
  margin: 0;
}

.fakePage .interview .terms h1::before {
  content: "";
  display: none;
}

.fakePage .terms section {
  display: flex;
  flex-flow: column nowrap;
  gap: 1em;
  line-height: 1;
}
.fakePage .terms section .term h2 {
  font-size: 1.5rem;
  font-weight: 900;
  display: inline;
  line-height: 1;
  margin-inline-end: 0.5em;
}

.fakePage .terms section .term p {
  display: inline;
  font-family: "Narkissim";
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1;
}

.fakePage .terms section .term p > u {
  text-decoration: 2px dotted underline;
  text-underline-offset: 0.2em;
}

.fakePage article {
  max-width: 100%;
  color: black;
  display: flex;
  flex-flow: column nowrap;
  gap: 2em;
  cursor: default;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: white;
  transition: background-color 0.1s ease-out;
  transition-property: background-color, color;
  width: 100%;
  flex-grow: 1;
}

.fakePage article header {
  background-color: var(--theme-color);
  display: flex;
  flex-flow: column nowrap;
  gap: 4em;
  position: sticky;
  top: 0;
  color: black;
  z-index: 1;
}

.fakePage article header hgroup {
  flex-flow: column-reverse nowrap;
  padding: 1em 0.4em;
  display: flex;
  max-width: 99%;
  gap: 0em;
}
.fakePage article header hgroup h1 {
  font-family: var(--ff-haim-cond), var(--ff-futura);
  font-weight: 700;
  /* font-feature-settings: "ss01"; */
  font-size: clamp(1.5em, min(10vw, 12vh), 25em);
  line-height: 0.8em;
  color: black;
}
.fakePage article header hgroup h1.title .altGlyph {
  font-feature-settings: "ss01" 1;
}

.fakePage article header hgroup h2 {
  font-family: var(--ff-narkis-yair);
  font-size: 1.2rem;
  line-height: 1.2rem;

  max-width: 450px;
}

.fakePage article header hgroup h2::before {
  content: "[ ";
}

.fakePage article header hgroup h2::after {
  content: " ]";
}

.fakePage article header hgroup h3 {
  display: none;
  font-family: var(--ff-narkis-yair);
  color: var(--color-green);
  font-size: 1.5rem;
}

.fakePage .lead {
  font: var(--font-kotz-500);
  font-weight: 700;
  padding: 24px;
  line-height: 1.3;
  background-color: transparent;
}

.fakePage article .bodyImage {
  max-width: 100%;
  margin: 2em 0;
  display: flex;
  flex-flow: column nowrap;
  width: max-content;
}
.fakePage article .bodyImage img {
  border-radius: 4px;
  width: 100%;
}

.fakePage article .bodyImage footer {
  color: var(--color-green);
  font-size: var(--fs-300);
  font-family: var(--ff-narkis-yair);
  text-align: left;
  margin-top: 3px;
  width: 100%;
}

.fakePage figure {
  display: flex;
  flex-flow: column nowrap;
}
.fakePage figure img {
  /* height: 17em; */
  /* width: auto; */
}

.fakePage figure figcaption {
  font-family: var(--ff-narkis-yair);
  font-size: var(--fs-300);
}

.fakePage main > .h1Spacer {
  height: 2em;
}

.fakePage main > h1 {
  margin-block: 2em 0.5em;
  color: black;
  background-color: var(--theme-color);
  max-width: max-content;
  padding-inline: 8px;
  font-family: var(--ff-narkis-yair);
  font-size: 1.58rem; /* var(--fs-600); */
  letter-spacing: 1px;
}

.fakePage main h2 {
  margin-block: 2em 0.5em;
  font: var(--font-kotz-500);
  font-weight: 600;
  letter-spacing: 4px;
}

.fakePage main.interview h1 {
  line-height: 1em;
  font-family: var(--ff-greta);
  font-style: italic;
  font-weight: 600;
  display: inline;
  margin-inline-end: 0.5em;
  margin-block-start: 2em;
  margin-block-end: 0;
}

.fakePage main.interview h1::before {
  content: "";
  background-image: url(../images/other/paragraph-vector.svg);
  background-size: contain;
  height: 2.5em;
  width: 2em;
  display: inline-block;
  background-repeat: no-repeat;
  margin-inline-end: 0.5em;
  float: inline-start;
  background-position: center center;
}

.fakePage main > p {
  font: var(--font-kotz-500);
  font-weight: 500;
  line-height: 1.77rem;
  letter-spacing: 0.4px;
  margin: 0;
}

.fakePage main > p a {
  background-color: var(--theme-color);
  color: black;
  padding: 0.5em 1em;
  font-weight: 700;
  border-radius: 100px;
  color: white;
  letter-spacing: 2px;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));
  transition: all 0.2s ease-out;
}

.fakePage main > p a:hover {
  filter: drop-shadow(0 0 7px rgba(0, 0, 0, 0.5));
}

.fakePage main > p a:active {
  filter: drop-shadow(0 0 12px rgba(0, 0, 0, 0.5));
}

.fakePage main > p:not(:first-child) {
  text-indent: var(--typo-indent);
}

.fakePage main > p b {
  font-family: var(--ff-narkis-yair);
  font-size: 1.4rem;
}

.fakePage main > p i {
  background-color: var(--theme-color);
  font-weight: 700;
}

.fakePage main .interview p {
  text-indent: 0;
}

.fakePage main .interview p::before {
  content: "";
  display: inline-block;
  height: 0.7em;
  width: var(--typo-indent);
  background-color: var(--color-theme-foreground);
  margin-inline-end: 0.3em;
  transition: background-color 0.1s ease-out;
}

.fakePage main > h3 {
  font: var(--font-kotz-500);
  font-weight: 600;
  line-height: 1.3em;
  letter-spacing: 0.7px;
  margin: 0;
  color: var(--theme-color);
  text-align: center;
  font-size: 1.6em;
  margin: 2em auto;
}

.fakePage hr {
  display: none;
}
.fakePage + .h1Spacer {
  height: 0;
}

.fakePage footer {
  font-family: var(--ff-narkis-yair);
  font-size: 1.4rem;

}
.fakePage footer > main {
  display: flex;
  flex-flow: row nowrap;
  /* gap: 1em; */
  background-color: var(--theme-color);
  margin: 40px clamp(0px, 14vw, 180px);
  margin-inline-end: 0;
  padding: 2em 1em;
  justify-content: flex-start;
  z-index: 10;
  margin-bottom: 0;
  max-width: fit-content;
  margin-right: 0;
}

.fakePage footer > main > .imageContainer {
  flex-shrink: 0;
  margin-inline-end: 1em;
}
.fakePage footer > main > .imageContainer > img {
}

.fakePage footer > main > .textBody {
  display: flex;
  flex-flow: column nowrap;
  flex: 4;
  max-width: 56ch;
}
.fakePage footer > main > .textBody > h1 {
  font-family: inherit;
  font-weight: 700;
}

.fakePage article.video {
  gap: 0;
  padding-block-end: 0;
  min-height: auto;
  max-height: 100%;
  overflow: hidden;
}

.fakePage main.video {
  width: 100%;
  max-width: none;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
}

.videoFooter {
  /* min-height: 40px; */
  width: 100%;
  background-color: var(--theme-color);
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  align-items: start;
}

.videoFooter *:first-child {
  font-family: var(--ff-haim-cond);
  font-size: 86px;
  line-height: 0.5;
  padding-bottom: 0.15em;
}

.videoFooter *:last-child {
  font-family: var(--ff-narkis-yair);
}

.videoContainer {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  overflow: hidden;
}
.videoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Landscape */
@media (min-aspect-ratio: 1), (orientation: landscape) {
  .fakePage {
    position: static;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
  }

  .fakePage article header hgroup h2 {
    display: none;
  }
}

/* Mobile */
@media (max-aspect-ratio: 1), (orientation: portrait) {
  .fakePage article {
    padding-inline-end: 0;
  }
  .fakePage article > main {
    max-width: 60ch;
  }

  .fakePage footer > main {
    flex-direction: column;
    max-width: 100%;
    margin-right: 0;
  }
  .fakePage footer > main .imageContainer > img {
    max-width: 30%;
  }
}
