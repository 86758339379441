@font-face {
  font-family: "DavidVC";
  src: url("./david_bezalel/VC_david-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "DavidVC";
  src: url("./david_bezalel/VC_david-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "DavidVC";
  src: url("./david_bezalel/VC_david-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "DavidKotz";
  src: url("./david_kotz/VC_david_Kotz-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "DavidVC";
  src: url("./david_bezalel/VC_david-Cursive.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "NarkisYair";
  src: url("./narkis_yair_fontef/NarkissYair-Bold-TRIAL.otf") format("woff");
  font-weight: 900;
  font-style: bold;
}

@font-face {
  font-family: "NarkisYair";
  src: url("./narkis_yair_fontef/NarkissYair-Regular-TRIAL.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NarkisYair";
  src: url("./narkis_yair_fontef/NarkissYair-Light-TRIAL.woff2") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Hadassa";
  src: url("./hadassah_friedlaender_fontef/HadassahFriedlaender-Regular.woff2")
    format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Hadassa";
  src: url("./hadassah_friedlaender_fontef/HadassahFriedlaender-Bold.woff2")
    format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Hadassa";
  src: url("./hadassah_friedlaender_fontef/HadassahFriedlaender-Black.woff2")
    format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Greta";
  src: url("./greta_text_hagilda/GretaTextH+L-BoldItalic+.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Greta";
  src: url("./greta_text_hagilda/GretaTextH+L-MediumItalic+.woff2")
    format("woff2");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Greta";
  src: url("./greta_text_hagilda/GretaTextH+L-RegularItalic.woff2")
    format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "KotzDisplay";
  src: url("./shira_saar/Shira_Saar_DisplaySS01.woff2") format("woff2");
}

@font-face {
  font-family: "KotzText";
  src: url("./shira_saar/Shira_Saar_text-Bold.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "KotzText";
  src: url("./shira_saar/Shira_Saar_text-Medium_Green-SVG.woff2")
    format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "BordersDavid";
  src: url("./borders_david/VC_david-Medium.woff2")
    format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Alpha Bravo";
  src: url("./shira_saar/Shira_Saar_text-Medium_Green-SVG.woff2")
    format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Narkissim";
  src: url("./Narkissim/Narkissim-Regular-TRIAL.otf") format("woff2");
  font-weight: 300;
}

@font-face {
  font-family: "Narkissim";
  src: url("./Narkissim/Narkissim-Bold-TRIAL.otf") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Leon";
  src: url("./leon_hafontia/Leon-Regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "HaimCondensed";
  src: url("./haim_hagilda/HaimG-Condensed.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Haim";
  src: url("./haim_hagilda/HaimG-Regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Haim";
  src: url("./haim_hagilda/HaimG-Bold.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "HaimTzar";
  src: url("./haim_vc/VC_Haim-Tzar.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Futura";
  src: url("./futura/FuturaStd-CondensedBold.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Discordia";
  src: url("./discordia/Discordia-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Discordia";
  src: url("./discordia/Discordia-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Discordia";
  src: url("./discordia/Discordia-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Dayafter";
  src: url("./dayafter/thedayafter-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "FrankG";
  src: url("./frankg/FrankG-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "FrankG";
  src: url("./frankg/FrankG-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
